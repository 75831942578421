import { graphql } from "@/v2/repo/graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "@/v2/repo/repo-error.enum";

export async function fetchWidgetSettings(
  businessId: string
): Promise<IFetchWidgetSettingsResponse> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        businessId,
      },
    });

    return response.data.business.widgetSettings;
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}

export interface IFetchWidgetSettingsResponse {
  baseColor: string;
  buttonText: string;
  headline: string;
  icon: string;
  message: string;
  textColor: string;
}

































































































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  computed,
  PropType,
  ref,
  onBeforeUnmount,
} from "@vue/composition-api";
import {
  AtomButtonLink,
  AtomButtonLinkSizeEnum,
  AtomButtonLinkTypeEnum,
  AtomIcon,
  AtomText,
  AtomTextTypeEnum,
  AtomDate,
  AtomMoney,
  MolGuideLink,
  MolGuideLinkArticleEnum,
  MolTableAction,
  MolTableActionTypeEnum,
  OrgHeaderInfo,
  OrgTable,
  AtomTextColorEnum,
} from "@/v2/new-design-system";
import { fetchWidgetSettings } from "@/v2/repo/fetch-widget-settings";
import { PaymentLinkQrCode } from "../qr-code";
import { useHelpers } from "@/v2/composable/use-helpers";
import { paymentLinkTrack } from "../track";
import { EventEnum } from "@/v2/enum";
import { t, tc } from "@/i18n";
import { IPaymentLink } from "@/v2/repo/payment-link/fetch-payment-links";
import { Toast } from "@/design-system";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { useRouter } from "@/router";
import { PaymentLinkStatus } from "../status";

const css = bemBuilder("payment-link-list");

type TypeStatus = "paid" | "pending";

export default defineComponent({
  name: "PaymentLinkList",
  components: {
    AtomButtonLink,
    AtomIcon,
    AtomText,
    AtomDate,
    AtomMoney,
    MolGuideLink,
    MolTableAction,
    OrgHeaderInfo,
    OrgTable,
    PaymentLinkQrCode,
    PaymentLinkStatus,
  },
  props: {
    paymentLinks: {
      type: Array as PropType<IPaymentLink[]>,
      required: true,
      default: Array,
    },
    businessId: {
      type: String,
      required: true,
    },
    onDeletePaymentLink: {
      type: Function as PropType<
        (paymentLinkId: string, paymentLinkUid: string) => void
      >,
      required: true,
    },
  },
  setup(props) {
    const currentInstance = getCurrentInstance();
    const showModalQRCode = ref(false);
    const paymentLinkURL = ref("");
    const brandColor = ref("");

    // @ts-ignore
    const analytics = currentInstance?.proxy.$analytics;

    const currentBusiness = computed(() => {
      return useHelpers().getCurrentBusiness(props.businessId);
    });

    const currencyCode = computed(() => {
      return currentBusiness.value?.currency?.code;
    });

    const paymentLinksList = computed(() => {
      return props.paymentLinks.map((plink) => ({
        uniqueId: plink.id,
        ...plink,
      }));
    });

    onMounted(() => {
      getWidgetSettings();
    });

    async function getWidgetSettings() {
      try {
        const widgetSettings = await fetchWidgetSettings(props.businessId);
        brandColor.value = widgetSettings?.baseColor;
      } catch {
        generalErrorToast();
      }
    }

    const router = useRouter();
    function goToViewPaymentLink(paymentLinkId: string) {
      router.push({
        name: "payment-links.view",
        params: {
          businessId: props.businessId,
          paymentLinkId,
        },
      });
    }

    function generatePaymentLink(urlId: string) {
      paymentLinkURL.value = `${currentBusiness.value?.orderingLink}/plink/${urlId}`;
    }

    async function copyLink(urlId: string) {
      try {
        await generatePaymentLink(urlId);
        window.navigator.clipboard.writeText(paymentLinkURL.value);

        new Toast().create({
          type: "success",
          title: t("module.payment_link.link_copied"),
        });
      } catch {
        generalErrorToast();
      }
    }

    async function viewQRCode(urlId: string) {
      await generatePaymentLink(urlId);
      showModalQRCode.value = true;
    }

    const columnsConfig = {
      payment: {
        header: () => t("module.payment_link.purpose"),
        sortable: false,
        sortDirection: "NONE",
        show: true,
        width: 200,
      },
      outletName: {
        header: () => t("module.payment_link.outlet_name"),
        sortable: true,
        sortDirection: "NONE",
        show: true,
        width: 200,
      },
      createdAt: {
        header: () => t("module.payment_link.creation_date"),
        sortable: true,
        sortDirection: "DESC",
        show: true,
        width: 165,
      },
      amount: {
        header: () => t("module.payment_link.amount"),
        sortable: true,
        sortDirection: "NONE",
        show: true,
        width: 135,
      },
      createdBy: {
        header: () => t("module.payment_link.created_by"),
        sortable: true,
        sortDirection: "NONE",
        show: true,
        width: 120,
      },
      status: {
        header: () => t("module.payment_link.status"),
        sortable: true,
        sortDirection: "NONE",
        show: true,
        width: 80,
      },
    };

    function eventTrack(event: EventEnum) {
      paymentLinkTrack(analytics, event, props.businessId, {
        page_name: "Payment links list page",
      });
    }

    function isPaid(status: TypeStatus) {
      return status === "paid";
    }

    const showDeleteButton = (paymentLink: IPaymentLink) => {
      const notPaid = !isPaid(paymentLink.status);
      const paidOnce = paymentLink.timesUsed > 0;
      return !paidOnce && notPaid;
    };

    return {
      t,
      tc,
      css,
      isPaid,
      showDeleteButton,
      EventEnum,
      eventTrack,
      brandColor,
      showModalQRCode,
      goToViewPaymentLink,
      copyLink,
      viewQRCode,
      paymentLinkURL,
      paymentLinksList,
      currencyCode,
      columnsConfig,
      AtomButtonLinkSizeEnum,
      AtomButtonLinkTypeEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      MolTableActionTypeEnum,
      MolGuideLinkArticleEnum,
    };
  },
});

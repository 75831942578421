































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import { PaymentLinkAgreement } from "../agreement";
import { PaymentLinkList } from "../list";
import { AtomLoading, MolModalConfirm } from "@/v2/new-design-system";
import {
  IFetchPaymentLinksResponse,
  fetchPaymentLinks,
} from "@/v2/repo/payment-link/fetch-payment-links";
import { deletePaymentLink } from "@/v2/repo/payment-link/delete-payment-link";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { Toast } from "@/design-system";
import { t } from "@/i18n";

const css = bemBuilder("payment-link-main");

export default defineComponent({
  name: "PaymentLinkMain",
  components: {
    AtomLoading,
    MolModalConfirm,
    PaymentLinkList,
    PaymentLinkAgreement,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(true);
    const hasPaymentLinks = ref(true);
    const paymentLinks = ref<IFetchPaymentLinksResponse["data"]>([]);

    onMounted(async () => {
      isLoading.value = false;

      await getPaymentLinks();
      hasPaymentLinks.value = paymentLinks.value.length > 0;
    });

    const deleteModalData = ref<{
      id: string;
      uid: string;
    } | null>(null);

    const onDeletePaymentLink = async (): Promise<void> => {
      if (deleteModalData.value === null) return;

      const recordId = deleteModalData.value.id;

      try {
        await deletePaymentLink({
          businessId: props.businessId,
          paymentLinkId: recordId,
        });

        paymentLinks.value = paymentLinks.value.filter(
          (plink) => plink.id !== recordId
        );

        new Toast().create({
          type: "success",
          title: t("module.payment_link.delete_payment_notify_success"),
        });
      } catch {
        generalErrorToast();
      } finally {
        deleteModalData.value = null;
      }
    };

    const getPaymentLinks = async () => {
      isLoading.value = true;

      const data = await fetchPaymentLinks({
        businessId: props.businessId,
      });

      paymentLinks.value = data ?? [];
      isLoading.value = false;
    };

    return {
      css,
      t,
      isLoading,
      getPaymentLinks,
      paymentLinks,
      hasPaymentLinks,
      deleteModalData,
      onDeletePaymentLink,
    };
  },
});






















import { bemBuilder } from "@/v2/util/bem-builder";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { AtomIcon, AtomText, AtomTextTypeEnum } from "@/v2/new-design-system";

const css = bemBuilder("payment-link-status");

type Status = "pending" | "paused" | "paid";

export default defineComponent({
  name: "PaymentLinkStatus",
  components: {
    AtomIcon,
    AtomText,
  },
  props: {
    status: {
      type: String as PropType<Status>,
      required: true,
    },
    timesUsed: {
      type: Number,
      required: true,
    },
    totalUses: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const config = computed(() => {
      const colorMap = {
        pending: {
          tagColor: "powder",
          contentColor: "rainy",
        },
        paused: {
          tagColor: "lemon-sorbet",
          contentColor: "canary",
        },
        paid: {
          tagColor: "pistachio",
          contentColor: "mint",
        },
      };

      const { tagColor, contentColor } = colorMap[props.status];

      return {
        tagColor,
        contentColor,
        showIcon:
          props.status === "paid" || props.timesUsed === props.totalUses,
      };
    });

    return {
      css,
      config,
      AtomTextTypeEnum,
    };
  },
});

import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";

interface IFetchPaymentsRequest {
  businessId: string;
  paymentLinkId: string;
  page: number;
  itemsPerPage: number;
}

interface IPaymentsParams {
  page: number;
  per_page: number;
}
type IDataResponse = {
  id: string;
  uid: string;
  customer_name: string;
  customer_email: string;
  date: string;
  currency: string;
  amount: number;
  recipient: string;
  status: string;
  url: any;
};

export type IPayment = {
  uid: string;
  date: Date;
  amount: number;
  customerName: string;
};

export interface IFetchPaymentsResponse {
  data: Array<IPayment>;
  paginator: {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
  };
}

export class FetchPaymentsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchPayments");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchPayments(
  request: IFetchPaymentsRequest
): Promise<IFetchPaymentsResponse> {
  let response: AxiosResponse;

  const params: IPaymentsParams = {
    page: request.page,
    per_page: request.itemsPerPage,
  };

  try {
    response = await http.get(
      `/api/v3/businesses/${request.businessId}/payment-links/${request.paymentLinkId}/payments`,
      {
        params,
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchPaymentsError(error);
  }

  return {
    data: response.data?.data.map((obj: IDataResponse) => ({
      uid: obj.uid,
      date: new Date(obj.date),
      amount: obj.amount,
      customerName: obj.customer_name,
      customerEmail: obj.customer_email,
    })),
    paginator: {
      total: response.data?.meta.total,
      perPage: response.data?.meta.per_page,
      currentPage: response.data?.meta.current_page,
      lastPage: response.data?.meta.last_page,
    },
  };
}









































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
} from "@/v2/new-design-system";
import { t } from "@/i18n";

const css = bemBuilder("payment-link-card-preview");

export default defineComponent({
  name: "PaymentLinkCardPreview",
  components: {
    AtomText,
  },
  props: {
    businessName: {
      type: String,
      required: true,
    },
    businessLogo: {
      type: String,
      default: "",
    },
    paymentValue: {
      type: String,
      default: "",
    },
    purposePayment: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});

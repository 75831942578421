































































































































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  ref,
  computed,
  watch,
} from "@vue/composition-api";
import {
  AtomLoading,
  AtomButton,
  AtomButtonSizeEnum,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomAutoSizeTextarea,
  OrgHeaderInfo,
  MolBackNav,
  MolGuideLink,
  MolGuideLinkArticleEnum,
  MolMultiselect,
  OrgFormMoneyInput,
  OrgFormInput,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { PaymentLinkCardPreview } from "../card-preview";
import { fetchOutlets } from "@/v2/repo/fetch-outlets";
import { OutletStateEnum } from "@/v2/enum";
import { createPaymentLink } from "@/v2/repo/payment-link/create-payment-link";
import { toLocaleCurrency } from "@/v2/util/to-locale-currency";
import { useHelpers } from "@/v2/composable/use-helpers";
import { useRouter } from "@/router";
import { paymentLinkTrack } from "../track";
import { EventEnum } from "@/v2/enum";
import { t, tc } from "@/i18n";

const css = bemBuilder("payment-link-create");

interface IOutlets {
  id: string;
  name: string;
}

export default defineComponent({
  name: "PaymentLinkCreate",
  components: {
    AtomLoading,
    AtomButton,
    AtomText,
    AtomAutoSizeTextarea,
    OrgHeaderInfo,
    MolBackNav,
    MolGuideLink,
    MolMultiselect,
    OrgFormMoneyInput,
    OrgFormInput,
    PaymentLinkCardPreview,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(true);
    const isCreating = ref(false);

    const amount = ref(0);
    const outletId = ref("");
    const purpose = ref("");
    const minNumberOfUses = 1;
    const maxNumberOfUses = 9999;
    const numberOfUses = ref(minNumberOfUses);
    const message = ref("");
    const maxLengthMessage = ref(300);

    const outletList = ref<IOutlets[]>([]);

    const router = useRouter();

    const currentBusiness = computed(() => {
      return useHelpers().getCurrentBusiness(props.businessId);
    });

    const currentInstance = getCurrentInstance();

    // @ts-ignore
    const analytics = currentInstance?.proxy.$analytics;

    const businessName = computed(() => {
      return currentBusiness.value?.name;
    });

    const businessLogo = computed(() => {
      return currentBusiness.value?.logo;
    });

    const locale = computed(() => {
      return currentBusiness.value?.language;
    });

    const currencyCode = computed(() => {
      return currentBusiness.value?.currency?.code;
    });

    const amountFormatted = computed(() => {
      return `${currencyCode.value} ${toLocaleCurrency(
        Number(amount.value) || 0,
        currencyCode.value,
        "0,0",
        locale.value
      )}`;
    });

    const numberOfUsesError = computed(() => {
      if (numberOfUses.value < minNumberOfUses)
        return t("module.payment_link.create.number_of_uses_min_error", {
          min: 1,
        });

      if (numberOfUses.value > maxNumberOfUses)
        return t("module.payment_link.create.number_of_uses_max_error", {
          max: maxNumberOfUses,
        });

      return "";
    });

    const messageCounter = computed(() => {
      return t("module.payment_link.x_characters", [
        message.value.length,
        maxLengthMessage.value,
      ]);
    });

    const isDisabled = computed(() => {
      const arrayValues: (string | number | boolean)[] = [
        outletId.value,
        amount.value,
        purpose.value,
        !numberOfUsesError.value,
      ];

      return arrayValues.some((value) => Boolean(value) === false);
    });

    watch(
      () => props.businessId,
      () => {
        getOutletList();
      },
      {
        immediate: true,
      }
    );

    async function getOutletList() {
      isLoading.value = true;

      try {
        const { data } = await fetchOutlets({
          businessId: props.businessId,
          perPage: 99,
          page: 0,
          state: [OutletStateEnum.ACTIVE, OutletStateEnum.DRAFT],
        });

        if (data.length) {
          outletList.value = data.map((obj: IOutlets) => ({
            id: obj.id,
            name: obj.name,
          }));

          if (outletList.value.length === 1) {
            outletId.value = outletList.value[0].id;
          }
        }
      } catch {
        outletList.value = [];
      } finally {
        isLoading.value = false;
      }
    }

    async function create() {
      isCreating.value = true;

      try {
        const id = await createPaymentLink({
          businessId: props.businessId,
          amount: amount.value,
          purpose: purpose.value,
          message: message.value,
          outletId: outletId.value,
          totalUses: Number(numberOfUses.value),
        });

        if (id) {
          new Toast().create({
            type: "success",
            title: t("label.success"),
            text: t("module.payment_link.create.notify_success"),
          });

          eventTrack(EventEnum.PAYMENT_LINK_CREATED);

          router.push({
            name: "payment-links.view",
            params: {
              businessId: props.businessId,
              paymentLinkId: id,
            },
          });
        }
      } finally {
        isCreating.value = false;
      }
    }

    function eventTrack(event: EventEnum) {
      paymentLinkTrack(analytics, event, props.businessId, {
        page_name: "Payment links create page",
      });
    }

    return {
      t,
      tc,
      css,
      isLoading,
      isCreating,
      create,
      EventEnum,
      eventTrack,
      isDisabled,
      outletList,
      currencyCode,
      businessName,
      businessLogo,
      amount,
      numberOfUses,
      minNumberOfUses,
      maxNumberOfUses,
      numberOfUsesError,
      locale,
      purpose,
      message,
      messageCounter,
      maxLengthMessage,
      outletId,
      amountFormatted,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      MolGuideLinkArticleEnum,
    };
  },
});

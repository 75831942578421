import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";

interface IFetchPaymentLinksRequest {
  businessId: string;
}

export interface IFetchPaymentLinksResponse {
  data: Array<IPaymentLink>;
}

export interface IPaymentLink {
  id: string;
  uid: string;
  urlId: string;
  status: "pending" | "paid";
  amount: number;
  purpose: string;
  message: string;
  outletName: string;
  createdAt: Date;
  createdBy: string;
  totalUses: number;
  timesUsed: number;
}

export class FetchPaymentLinksError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchPaymentLinks");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchPaymentLinks(
  request: IFetchPaymentLinksRequest
): Promise<Array<IPaymentLink>> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/api/v3/businesses/${request.businessId}/payment-links`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchPaymentLinksError(error);
  }

  return response.data.data.map((obj: any) => ({
    id: obj.id,
    uid: obj.uid,
    urlId: obj.url_id,
    status: obj.status,
    amount: obj.amount,
    purpose: obj.purpose,
    message: obj.message,
    outletName: obj.outlet_name ?? "",
    createdAt: new Date(obj.created_at),
    createdBy: obj.created_by,
    totalUses: obj.total_uses,
    timesUsed: obj.times_used,
  }));
}

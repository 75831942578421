import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";

interface IFetchPaymentLinkBody {
  businessId: string;
  paymentLinkId: string;
}

export interface IFetchPaymentLinkResponse {
  id: string;
  uid: string;
  urlId: string;
  status: "pending" | "paid";
  amount: number;
  purpose: string;
  totalUses: number;
  timesUsed: number;
  message: string;
  outletName: string;
  createdAt: Date;
  createdBy: string;
}

export class FetchPaymentLinkError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchPaymentLink");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchPaymentLink(
  request: IFetchPaymentLinkBody
): Promise<IFetchPaymentLinkResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/api/v3/businesses/${request.businessId}/payment-links/${request.paymentLinkId}`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchPaymentLinkError(error);
  }

  return {
    id: response.data.id,
    uid: response.data.uid,
    urlId: response.data.url_id,
    status: response.data.status,
    amount: response.data.amount,
    purpose: response.data.purpose,
    totalUses: response.data.total_uses || 1,
    timesUsed: response.data.times_used || 0,
    message: response.data.message,
    outletName: response.data.outlet_name,
    createdAt: response.data.created_at,
    createdBy: response.data.created_by,
  };
}






































































































































































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  ref,
  computed,
  onMounted,
} from "@vue/composition-api";
import {
  AtomLoading,
  AtomIcon,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  MolDeleteButton,
  MolModalConfirm,
  MolLinearProgress,
  MolPagination,
  AtomDate,
  AtomMoney,
  AtomDateTypeEnum,
  MolBackNav,
  OrgTable,
} from "@/v2/new-design-system";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { useHelpers } from "@/v2/composable/use-helpers";
import {
  fetchPaymentLink,
  IFetchPaymentLinkResponse,
} from "@/v2/repo/payment-link/fetch-payment-link";
import { deletePaymentLink } from "@/v2/repo/payment-link/delete-payment-link";
import { fetchWidgetSettings } from "@/v2/repo/fetch-widget-settings";
import {
  fetchPayments,
  IFetchPaymentsResponse,
  IPayment,
} from "@/v2/repo/payment-link/fetch-payments";
import { PaymentLinkQrCode } from "../qr-code";
import { paymentLinkTrack } from "../track";
import { EventEnum } from "@/v2/enum";
import { useRouter } from "@/router";
import { Toast } from "@/design-system";
import { report } from "@chatfood/bug-reporter";
import { t, tc } from "@/i18n";
import { PaymentLinkStatus } from "../status";

const css = bemBuilder("payment-link-view");

export default defineComponent({
  name: "PaymentLinkView",
  components: {
    AtomLoading,
    AtomIcon,
    AtomText,
    AtomButton,
    AtomDate,
    AtomMoney,
    MolBackNav,
    MolDeleteButton,
    MolModalConfirm,
    MolLinearProgress,
    MolPagination,
    OrgTable,
    PaymentLinkQrCode,
    PaymentLinkStatus,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    paymentLinkId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const isLoadingList = ref(false);
    const showModalDelete = ref(false);
    const showModalQRCode = ref(false);
    const brandColor = ref("");

    const paymentsList = ref<Array<IPayment>>([]);
    const paginationInfo = ref<IFetchPaymentsResponse["paginator"]>();

    const paymentLink = ref<IFetchPaymentLinkResponse>({
      id: "",
      uid: "",
      urlId: "",
      status: "pending",
      amount: 0,
      purpose: "",
      totalUses: 1,
      timesUsed: 0,
      message: "",
      outletName: "",
      createdAt: new Date(),
      createdBy: "",
    });

    const progressConfig = computed(() => {
      const colorMap = {
        pending: {
          progressColor: "rainy",
        },
        paused: {
          progressColor: "canary",
        },
        paid: {
          progressColor: "mint",
        },
      };

      const { progressColor } = colorMap[paymentLink.value.status];

      const paymentLeft =
        paymentLink.value.totalUses - paymentLink.value.timesUsed;

      const message = isPaid(paymentLink.value.status)
        ? t("module.payment_link.payments_finished")
        : tc("module.payment_link.payment_left", paymentLeft, {
            counter: paymentLeft || 0,
          });
      return {
        message,
        progressColor,
      };
    });

    const currentInstance = getCurrentInstance();

    // @ts-ignore
    const analytics = currentInstance?.proxy.$analytics;

    const router = useRouter();

    const currentBusiness = computed(() => {
      return useHelpers().getCurrentBusiness(props.businessId);
    });

    const locale = computed(() => {
      return currentBusiness.value?.language;
    });

    const currencyCode = computed(() => {
      return currentBusiness.value?.currency?.code;
    });

    const orderingLink = computed(() => {
      return currentBusiness.value?.orderingLink;
    });

    const totalPayments = computed(() => {
      return paginationInfo.value?.total ?? 0;
    });

    onMounted(async () => {
      isLoading.value = true;
      try {
        await Promise.all([
          getWidgetSettings(),
          getPaymentLink(),
          getPaymentsList(),
        ]);
      } finally {
        isLoading.value = false;
      }
    });

    async function getWidgetSettings() {
      try {
        const widgetSettings = await fetchWidgetSettings(props.businessId);
        brandColor.value = widgetSettings?.baseColor;
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      }
    }

    async function getPaymentLink() {
      try {
        const data = await fetchPaymentLink({
          businessId: props.businessId,
          paymentLinkId: props.paymentLinkId,
        });

        if (data) {
          paymentLink.value = data;
        }
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });

        redirectToPaymentLinkList();
        generalErrorToast(t("module.payment_link.payment_link_not_found"));
      }
    }
    const getPaymentsList = async (currentPage: number = 1) => {
      try {
        isLoadingList.value = true;
        const result = await fetchPayments({
          businessId: props.businessId,
          paymentLinkId: props.paymentLinkId,
          itemsPerPage: 10,
          page: currentPage,
        });
        paymentsList.value = result.data;
        paginationInfo.value = result.paginator;
      } catch (e) {
        report(e);
      } finally {
        isLoadingList.value = false;
      }
    };

    const columnsConfig = {
      customer: {
        header: () => t("module.payment_link.name"),
        show: true,
      },
      date: {
        header: () => t("module.payment_link.payment_date"),
        show: true,
      },
      amount: {
        header: () => t("module.payment_link.amount"),
        show: true,
      },
      uid: {
        header: () => t("module.payment_link.payment_id"),
        show: true,
      },
    };

    const paymentLinkURL = computed(() => {
      return `${orderingLink.value}/plink/${paymentLink.value.urlId}`;
    });

    function copyLink() {
      try {
        window.navigator.clipboard.writeText(paymentLinkURL.value);

        eventTrack(EventEnum.PAYMENT_LINK_SHARE_COPY_LINK);

        new Toast().create({
          type: "success",
          title: t("module.payment_link.link_copied"),
        });
      } catch {
        generalErrorToast();
      }
    }

    function shareWhatsapp() {
      eventTrack(EventEnum.PAYMENT_LINK_SHARE_WHATSAPP);
      window.open(
        `https://wa.me/?text=${paymentLinkURL.value}`,
        "_blank",
        "noopener, noreferrer"
      );
    }

    async function remove() {
      try {
        await deletePaymentLink({
          businessId: props.businessId,
          paymentLinkId: props.paymentLinkId,
        });

        new Toast().create({
          type: "success",
          title: t("module.payment_link.delete_payment_notify_success"),
        });

        redirectToPaymentLinkList();
      } catch {
        generalErrorToast();
      } finally {
        showModalDelete.value = false;
      }
    }

    function redirectToPaymentLinkList() {
      router.push({
        name: "payment-links.index",
        params: {
          businessId: props.businessId,
        },
      });
    }

    function eventTrack(event: EventEnum) {
      paymentLinkTrack(analytics, event, props.businessId, {
        page_name: "Payment links view page",
      });
    }

    function isPaid(status: "paid" | "pending") {
      return status === "paid";
    }

    const showDeleteButton = computed(() => {
      const notPaid = !isPaid(paymentLink.value.status);
      const paidOnce = paymentLink.value.timesUsed > 0;
      return !paidOnce && notPaid;
    });

    return {
      t,
      tc,
      css,
      isPaid,
      columnsConfig,
      isLoading,
      isLoadingList,
      showDeleteButton,
      showModalDelete,
      showModalQRCode,
      paymentLink,
      paymentLinkURL,
      paymentsList,
      paginationInfo,
      totalPayments,
      getPaymentsList,
      copyLink,
      shareWhatsapp,
      brandColor,
      locale,
      remove,
      progressConfig,
      currencyCode,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomDateTypeEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
    };
  },
});

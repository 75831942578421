import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";

interface ICreatePaymentLinkBody {
  businessId: string;
  amount: number;
  purpose: string;
  totalUses: number;
  message?: string;
  outletId?: string;
}

export class CreatePaymentLinkError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: CreatePaymentLink");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function createPaymentLink(
  request: ICreatePaymentLinkBody
): Promise<string> {
  let response: AxiosResponse;

  try {
    response = await http.post(
      `/api/v3/businesses/${request.businessId}/payment-links`,
      {
        amount: request.amount,
        purpose: request.purpose,
        outlet_id: request.outletId,
        message: request.message,
        total_uses: request.totalUses,
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new CreatePaymentLinkError(error);
  }

  return response.data.id;
}

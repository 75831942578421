







































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
} from "@vue/composition-api";
import {
  AtomIcon,
  AtomButtonLink,
  AtomButtonLinkSizeEnum,
  MolGuideLink,
  MolGuideLinkArticleEnum,
  OrgHeaderInfo,
} from "@/v2/new-design-system";
import { paymentLinkTrack } from "../track";
import { EventEnum } from "@/v2/enum";
import { t } from "@/i18n";

const css = bemBuilder("payment-link-agreement");

export default defineComponent({
  name: "PaymentLinkAgreement",
  components: {
    AtomIcon,
    AtomButtonLink,
    MolGuideLink,
    OrgHeaderInfo,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const currentInstance = getCurrentInstance();

    // @ts-ignore
    const analytics = currentInstance?.proxy.$analytics;

    onMounted(() => {
      eventTrack(EventEnum.PAYMENT_LINK_AGREEMENT_VIEW);
    });

    function eventTrack(event: EventEnum) {
      paymentLinkTrack(analytics, event, props.businessId, {
        page_name: "Payment links agreement page",
      });
    }

    return {
      t,
      css,
      EventEnum,
      eventTrack,
      AtomButtonLinkSizeEnum,
      MolGuideLinkArticleEnum,
    };
  },
});

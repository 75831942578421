













import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  ref,
  onMounted,
} from "@vue/composition-api";
import { AtomModal, AtomLoading } from "@/v2/new-design-system";
import { fetchQRCode, IQRCodeRequest } from "@/v2/repo/fetch-qr-code";
import { generalErrorToast } from "@/v2/util/general-error-toast";

const css = bemBuilder("payment-link-qr-code");

export default defineComponent({
  name: "PaymentLinkQrCode",
  components: {
    AtomModal,
    AtomLoading,
  },
  props: {
    linkToGenerate: {
      type: String,
      required: true,
    },
    brandLogo: {
      type: String,
      default: "",
    },
    brandColor: {
      type: String,
      default: "",
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const QRCodeLink = ref("");
    const isLoading = ref(false);

    onMounted(() => {
      getQRCode();
    });

    async function getQRCode() {
      isLoading.value = true;
      try {
        let additionalData: IQRCodeRequest = {};

        if (props.brandLogo) {
          additionalData = {
            ...additionalData,
            logoSize: 8,
            logoURL: props.brandLogo,
          };
        }
        if (props.brandColor) {
          additionalData = {
            ...additionalData,
            innerColor: props.brandColor,
          };
        }

        QRCodeLink.value = await fetchQRCode(
          props.linkToGenerate,
          additionalData
        );
      } catch {
        generalErrorToast();
        props.onClose();
      } finally {
        isLoading.value = false;
      }
    }

    return {
      css,
      QRCodeLink,
      isLoading,
    };
  },
});

import { IRepoErrors } from "../../errors";
import { http } from "../../http";

interface IDeletePaymentLinkBody {
  businessId: string;
  paymentLinkId: string;
}

export class DeletePaymentLinkError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: DeletePaymentLink");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function deletePaymentLink(
  request: IDeletePaymentLinkBody
): Promise<void> {
  try {
    await http.delete(
      `/api/v3/businesses/${request.businessId}/payment-links/${request.paymentLinkId}`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new DeletePaymentLinkError(error);
  }
}
